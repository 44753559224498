import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { Observable } from 'rxjs/internal/Observable';
import { ResponseModel } from '../../Models/ResponseModel';
import { SharedServiceService } from '../shared-service.service';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private dataService: DataService,
    private sharedService: SharedServiceService
  ) {}

  AuthenticateUser(data): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.PostData('authenticate/Login', data).pipe(
        tap((res: ResponseModel) => {
          if (res.status) {
            console.log(res);
          }
        })
      )
    );
  }

  AuthenticateSubMerchant(data): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.PostData('authenticate/LoginforSubMerchant', data)
    );
  }

  AuthenticateMobile(data): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.GetData('authenticate/GetDataFromToken?Token=' + data)
    );
  }

  multiUserLoginAuthentication(data): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.GetData('authenticate/MultipleRoleLogin?Roles=' + data)
    );
  }
  forgetPassword(data): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.GetData('authenticate/ForgotPassword?email=' + data)
    );
  }

  MultiUserForgotLink(Pid, Did, Email): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.GetData(
        'authenticate/MultiUserResetMail?PersonId=' +
          Pid +
          '&DistrictId=' +
          Did +
          '&email=' +
          Email
      )
    );
  }

  getUserDetails(): boolean {
    const token = this.sharedService.getToken();
    if (token) {
      const tokenData = this.sharedService.getDecodedAccessToken(token);
      if (tokenData) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isLoggedIn(): boolean {
    return this.getUserDetails();
  }

  DistrictOwnerLogin(data): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.PostData('authenticate/DistrictOwnerLogin', data)
    );
  }

  GetPersonEmailList(email): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.GetData(
        'authenticate/GetPersonEmailList?EmailAddress=' + email
      )
    );
  }

  GetLeadTrackingRecords(): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.GetData('authenticate/GetLeadTrackingData')
    );
  }

  GetAllCampaigns(): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.GetData('authenticate/GetAllCampaigns')
    );
  }

  GetAllSessions(): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.GetData('authenticate/GetAllSesions')
    );
  }

  GetAutoLoginPassword(email: string): Observable<string> {
    return <Observable<string>>(
      this.dataService.GetData(
        'authenticate/GetAutoLogIn?EmailAddress=' + email
      )
    );
  }

  LeadTrackingLog(
    Category,
    Label,
    Campaign,
    url,
    SessionId,
    email
  ): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.GetData(
        'authenticate/LeadTrackingLog?Category=' +
          Category +
          '&Label=' +
          Label +
          Label +
          '&Campaign=' +
          Campaign +
          '&url=' +
          url +
          '&SessionId=' +
          SessionId +
          '&email=' +
          email
      )
    );
  }

  SSoLoginCLick(code): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.GetData('authenticate/SSoLoginCLick?code=' + code)
    );
  }

  GetResetPasswordData(code: string): Observable<ResponseModel> {
    return <Observable<ResponseModel>>(
      this.dataService.GetData('authenticate/ResetPassword?encryption=' + code)
    );
  }
}
